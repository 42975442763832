.loading-container {
  padding: 0;
  margin: 0;
  position: absolute;

  background-color: hsla(0, 0%, 100%, 0.75);

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  z-index: 2;
}


.loading {
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
}

.loading div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 78px;
  height: 78px;
  margin: 8px;
  border: 8px solid #75ac40;
  border-radius: 50%;
  animation: loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #75ac40 transparent transparent transparent;
}

.loading div:nth-child(1) {
  animation-delay: -0.45s;
}

.loading div:nth-child(2) {
  animation-delay: -0.3s;
}

.loading div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}