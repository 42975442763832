* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.app {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.document {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar {
  width: 100%;
  padding: 1rem 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background-color: #181818;
}

.navbar p {
  margin: 0 1rem;
  color: #fff;
}

.navbar button {
  width: 100%;
  max-width: 180px;
  padding: 1rem 2rem;
  border: none;
  border-radius: .25rem;
  background-color: #fff;
}

@media (min-width: 720px) {
  .document {
    margin-top: -1rem;
  }
}